import { Component } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Container } from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapGetters, mapState } from 'vuex'
import GtrEventMenu from '../company-events/event-menu/event-menu.view'
import { GtrFontPackage, GtrNewFontPackage } from 'gtr-types'

@Component({
  name: 'GtrCompanyFonts',
  computed: {
    ...mapState('media', ['fonts']),
    ...mapGetters('media', ['fontList'])
  },
  components: {
    'gtr-event-menu': GtrEventMenu
  }
})
export default class GtrCompanyFonts extends GtrSuper {
  // component props and data
  loading = false // is loading state.
  displayUploadForm = false // flag for upload modal.
  displayUpdateForm = false

  // Getter for fontList computed propert from store.
  fontList!: any[]

  newFontTTF: File | null = null
  newFontWOFF: File | null = null
  packageDescription: string | null = null

  fontPackageToUpdate: GtrFontPackage | null = null;

  headers = [
    { text: 'Font Family', align: 'start', sortable: false, value: 'font_family' },
    { text: 'Description', align: 'start', sortable: false, value: 'description' },
    { text: 'Created By', align: 'start', sortable: false, value: 'created_by_name' },
    { text: 'Updated At', align: 'start', sortable: false, value: 'updated_at' },
    { text: 'Created At', align: 'start', sortable: false, value: 'created_at' },
    { text: '', align: 'start', sortable: false, value: 'actions' }
  ];

  // Computed properties
  get fontPackage (): GtrNewFontPackage {
    return {
      ttf: this.newFontTTF,
      woff: this.newFontWOFF,
      description: this.packageDescription
    }
  }

  get fontPackageBreakdown (): string {
    return JSON.stringify({
      ttf: this.newFontTTF?.name ?? null,
      woff: this.newFontWOFF?.name ?? null
    }, null, 4)
  }

  get validFontPacakgeDescription (): boolean {
    return (this.packageDescription !== null && this.packageDescription !== '' && this.packageDescription.trim().length > 1)
  }

  // getters to help with font package validation.
  get validFontPackage (): boolean {
    return (this.bothFontFiles && this.fontNamesMatch && this.validFontPacakgeDescription)
  }

  // make sure both files are not null.
  get bothFontFiles (): boolean {
    return (this.newFontTTF !== null && this.newFontWOFF !== null)
  }

  // make sure the font names match.
  get fontNamesMatch (): boolean {
    if (!this.bothFontFiles) return false
    const [ttfName] = (this.newFontTTF?.name || '').split('.ttf')
    const [woffName] = (this.newFontWOFF?.name || '').split('.woff')
    return ttfName === woffName
  }

  // lifecycle hooks.
  async mounted () {
    await this.fetchFonts()
  }

  // functions and handlers
  /**
   * Fetch the font packages for this company.
   */
  private async fetchFonts (): Promise<void> {
    try {
      this.loading = true
      const { uuid: companyUuid } = this.$route.params
      await this.$store.dispatch('media/getFontStores', companyUuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  /**
   * handler for font file uploads.
   * @todo refactor this to bundle fonts.
   * @param {File[]} files[]
   */
  async onGtrFileUploadChange (files: File[]): Promise<void> {
    try {
      this.loading = true
      const [file] = files // get the first file.
      if (file.name.includes('.ttf')) {
        this.newFontTTF = file
      } else if (file.name.includes('.woff')) {
        this.newFontWOFF = file
      } else {
        throw new Error('Invalid file type')
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false // lastly set loading back to false
    }
  }

  /**
   * validate the font package to make sure it's correct.
   */
  private validateFontPackageForSubmission (): void {
    if (!this.bothFontFiles) {
      throw new Error('You must provide both a ttf and woff')
    }

    if (!this.fontNamesMatch) {
      throw new Error('You must provide ttf and woff for the same font')
    }

    if (!this.validFontPacakgeDescription) {
      throw new Error('You must provide a package description')
    }
  }

  /**
   * Save a new font package.
   */
  async saveNewFontPackage (): Promise<void> {
    try {
      this.loading = true
      this.validateFontPackageForSubmission()
      const response = await this.$store.dispatch('media/createFontPackage', {
        companyUuid: this.$route.params.uuid,
        fontPackage: this.fontPackage
      })
      Container.get(Notification).success('Font package saved.')
      this.cancelNewFontPackage()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  requireDescription (value: string) {
    if (!this.packageDescription) {
      return !!value || 'Required.'
    }
    return true
  }

  // async saveUpdateFontPackage () {
  //   try {
  //     this.loading = true
  //     if (this.fontPackageToUpdate === null) {
  //       throw new Error('There is no package to edit.')
  //     }
  //     const response = await this.$store.dispatch('media/updateFontPackage', {
  //       companyUuid: this.$route.params.uuid,
  //       fontPackage: this.fontPackageToUpdate,
  //       fontPackageUuid: this.fontPackageToUpdate.uuid
  //     })
  //     Container.get(Notification).success('Font package updated.')
  //     this.cancelUpdateFontPackage()
  //   } catch (error) {
  //     Container.get(ErrorHandlerService).error(error)
  //   } finally {
  //     this.loading = false
  //   }
  // }

  /**
   * Sends a delete request for a given fontpackage.
   * @param fontPackage the font package to be deleted.
   */
  async deleteFontPackage (fontPackageUuid: string): Promise<void> {
    try {
      this.loading = true
      const { uuid: companyUuid } = this.$route.params
      const response = await this.$store.dispatch('media/deleteFontPackage', {
        companyUuid,
        fontPackageUuid
      })
      Container.get(Notification).success('Font package deleted.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.loading = false
    }
  }

  /**
   * Close the upload modal and reset the new fonts.
   */
  cancelNewFontPackage (): void {
    this.hideUploadForm()
    this.newFontWOFF = null
    this.newFontTTF = null
    this.packageDescription = null
  }

  editFontPackage (item: any): void {
    this.fontPackageToUpdate = item
    this.displayUpdateForm = true
  }

  cancelUpdateFontPackage (): void {
    this.hideUpdateForm()
    this.fontPackageToUpdate = null
  }

  /**
   * if the displayUploadForm flag is false to true
   */
  toggleUploadForm (): void {
    if (!this.displayUploadForm) {
      this.displayUploadForm = true
    }
  }

  /**
   * set the displayUploadForm to false
   */
  hideUploadForm (): void {
    this.displayUploadForm = false
  }

  hideUpdateForm (): void {
    this.displayUpdateForm = false
  }
}
