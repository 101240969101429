import { Component, Prop, Vue } from 'vue-property-decorator'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrEventMenu'
})
export default class GtrEventMenu extends Vue {
  @Prop() event!: Record<string, any>

  handleEventView () {
    this.$store.dispatch('event/setEvent', this.event)
    this.$router.push({ name: 'level-two.event.event-info', params: { event_uuid: this.event.uuid } })
  }

  async handleArchiveEvent () {
    try {
      await this.$store.dispatch('event/changeEventStatus', {
        event_uuid: this.event.uuid,
        data: { status: 'ARCHIVED' }
      })
      Container.get(Notification).success('Event successfully archived.')
      await this.fetchEvents()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async handleUnarchiveEvent () {
    try {
      await this.$store.dispatch('event/changeEventStatus', {
        event_uuid: this.event.uuid,
        data: { status: 'ACTIVE' }
      })
      Container.get(Notification).success('Event successfully unarchived.')
      await this.fetchEvents()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchEvents () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('event/loadEventsForCompany', { company_uuid: this.$route.params.uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
